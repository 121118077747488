import { RouterModule, Routes } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { LayoutModule } from '../../shared/components/layout/layout.module';
import { MainComponent } from './main.component';
import { MessageService } from 'primeng/api';
import { NgModule } from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';
import { TranslateModule } from '@ngx-translate/core';

const primeng = [OverlayPanelModule, DropdownModule, ToastModule, DialogModule, ButtonModule];

const routes: Routes = [
    {
        path: ``,
        component: MainComponent,
        children: [
            {
                path: ``,
                loadChildren: async () => {
                    return import(`./views/home/home.module`).then((m) => {
                        return m.HomeModule;
                    });
                },
            },
            {
                path: `faq`,
                loadChildren: async () => {
                    return import(`./views/faq/faq.module`).then((m) => {
                        return m.FaqModule;
                    });
                },
            },
            {
                path: `regulamin`,
                loadChildren: async () => {
                    return import(`./views/terms-of-service/terms-of-service.module`).then((m) => {
                        return m.TermsOfServiceModule;
                    });
                },
            },
            {
                path: `kontakt`,
                loadChildren: async () => {
                    return import(`./views/contact/contact.module`).then((m) => {
                        return m.ContactModule;
                    });
                },
            },
            {
                path: `uzytkownik`,
                loadChildren: async () => {
                    return import(`./views/user/user.module`).then((m) => {
                        return m.UserModule;
                    });
                },
            },
            {
                path: `kalkulator`,
                loadChildren: async () => {
                    return import(`./views/calculator/calculator.module`).then((m) => {
                        return m.CalculatorModule;
                    });
                },
            },
            {
                path: `sklep`,
                loadChildren: async () => {
                    return import(`./views/shop/shop.module`).then((m) => {
                        return m.ShopModule;
                    });
                },
            },
            {
                path: `:transportAuthority`,
                children: [
                    {
                        path: ``,
                        loadChildren: async () => {
                            return import(`./views/home/home.module`).then((m) => {
                                return m.HomeModule;
                            });
                        },
                    },
                    {
                        path: `bilety`,
                        loadChildren: async () => {
                            return import(`./views/tickets/tickets.module`).then((m) => {
                                return m.TicketsModule;
                            });
                        },
                    },
                    {
                        path: `**`,
                        redirectTo: `/`,
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [
        ...primeng,
        CommonModule,
        LayoutModule,
        RouterModule.forChild(routes),
        TranslateModule,
    ],
    declarations: [MainComponent],
    providers: [MessageService],
})
export class MainModule {}
