<app-footer>
    <div class="mr-5">
        <button btn-icon class="mr-1" aria-label="zmniejsz czcionkę na stronie" icon="wkd-decrease-font"
            (click)="decreaseFontSize()"></button>

        <button btn-icon aria-label="zwiększ czcionkę na stronie" icon="wkd-increase-font"
            (click)="increaseFontSize()"></button>
    </div>
</app-footer>

<header class="mx-auto" id="header-container">
    <app-navigation *ngIf="navigation$ | async as navigation" [mobileNavigation]="navigation"
        [desktopNavigation]="navigation">
        <!-- Mobile -->
        <ng-template #mobileHeaderTemplate>
            <div class="flex items-center">
                <img class="mera-logo inline-block cursor-pointer outline-none" [src]="logo" alt="logo Mera Systemy"
                    (click)="navigateHome()" />
            </div>
            <div class="p-4 justify-self-end flex row">
                <div class="relative">
                    <img class="cursor-pointer flex self-center shopping-cart-img" width="28" height="28"
                        src="/assets/icons/004-shopping-cart-empty-side-view.svg" (click)="cartShow()" />
                    <div class="badge">
                        <div>{{ checkShoppingListCount() }}</div>
                    </div>
                </div>

                <ng-container [ngTemplateOutlet]="langSwitch"></ng-container>
            </div>
            <div class="mobile-transport-authority-wrapper px-4 py-2"
                [ngClass]="{ hidden: !(showHeaderTransportAuthoritiesDropdown$ | async) }">
                <ng-container *ngIf="transportAuthorities.length > 1">
                    <p-dropdown [filter]="true" filterBy="name" [style.width.px]="150" placeholder="Wybierz miasto"
                        optionLabel="name" [(ngModel)]="selectedTransportAuthority"
                        [options]="transportAuthorities$ | async"
                        (onChange)="onSelectedTransportAuthority($event)"></p-dropdown>
                </ng-container>
            </div>
        </ng-template>

        <!-- Desktop -->
        <ng-template #desktopBeforeTemplate>
            <div class="flex justify-center items-center">
                <img class="mera-logo cursor-pointer outline-none" [src]="logo" alt="Mera Systemy logo"
                    (click)="navigateHome()" />
            </div>
        </ng-template>

        <ng-template #desktopAfterTemplate>
            <div class="grid grid-flow-col gap-6 self-center">
                <div class="grid gap-2 grid-flow-col items-center justify-center">
                    <ng-container *ngIf="transportAuthorities.length > 1">
                        <p-dropdown [filter]="true" filterBy="name" [ngClass]="{
                                invisible: !(showHeaderTransportAuthoritiesDropdown$ | async)
                            }" [style.width.px]="150" placeholder="Wybierz miasto" optionLabel="name"
                            [(ngModel)]="selectedTransportAuthority" [options]="transportAuthorities$ | async"
                            (onChange)="onSelectedTransportAuthority($event)"></p-dropdown>
                    </ng-container>

                    <div class="relative">
                        <img class="cursor-pointer flex self-center shopping-cart-img" width="28" height="28"
                            src="/assets/icons/004-shopping-cart-empty-side-view.svg" (click)="cartShow()" />
                        <div class="badge">
                            <div>{{ checkShoppingListCount() }}</div>
                        </div>
                    </div>

                    <ng-container [ngTemplateOutlet]="additionalContent"></ng-container>
                </div>
            </div>
        </ng-template>
    </app-navigation>
</header>

<ng-template #additionalContent>
    <a class="lang-switch ml-4 font-bold" role="button" (click)="translationService.switchLang()">{{
        'navigation.langSwitch' | translate }}</a>
</ng-template>

<!-- ------------------- popup with transport authority -------------------- -->

<p-dialog class="grid" position="center" modal="true" [contentStyle]="{ overflow: 'visible' }" [closable]="false"
    [draggable]="false" [visible]="(showChooseTransportAuthorityDialog$ | async) && transportAuthorities.length > 1"
    [resizable]="false">
    <div class="grid grid-flow-row justify-center gap-2">
        <img width="208" height="194" class="inline-block outline-none poland-map" src="/assets/icons/022-poland.svg"
            alt="Poland map" />
        <p-dropdown [filter]="true" filterBy="name" optionLabel="name" [options]="transportAuthorities$ | async"
            placeholder="Wybierz miasto" (onChange)="onDialogDropdownSelectedTransportAuthority($event)"></p-dropdown>
    </div>
</p-dialog>

<!-- ---------------------------- shopping cart ---------------------------- -->
<p-dialog class="grid cart-dialog" [header]="'cart.header' | translate" [(visible)]="displayCartDialog"
    [closable]="true" [draggable]="false" [resizable]="false" position="top-right" (onHide)="cartHide()" [style]="{
        'position': 'absolute',
        'right': headerMargin
    }">
    <ng-template pTemplate>
        <div class="">
            <div class="tickets-wrapper">
                <!--iteracja po kartach-->
                <div *ngFor="let ticketCarrier of ticketCarriers" class="ticket-carrier-wrapper grid grid-flow-row">
                    <div *ngIf="!isDefaultTicketCarrier(ticketCarrier)" class="flex py-1 pl-3 items-center">
                        <i class="text-xl pi pi-id-card mr-2"></i>
                        <div class="card-number font-bold text-lg pl-1">
                            {{ ticketCarrier.cardNumber }}
                        </div>
                    </div>
                    <!--iteracja po biletach-->
                    <div *ngFor="let item of newShoppingListItems; let index = index">
                        <div *ngIf="ticketCarrier.id === item.ticketCarrierId" class="ticket-view-wrapper w-full grid"
                            [class.items-center]="item.ticket">
                            <div *ngIf="item.ticket; else productCard" class="flex justify-start pl-1"
                                [title]="(item.ticket || item.product).name">
                                <div class="grid-container bus-ticket bg-cover">
                                    <div class="icon"></div>
                                    <div class="content relative pt-1">
                                        <i class="md:text-xl text-sm pi pi-times-circle cursor-pointer absolute top-3 right-2"
                                            (click)="removeShoppingItemFromCart(item)"></i>
                                        <div class="rounded-none">
                                            <div class="content-name rounded-none">{{ (item.ticket || item.product).name
                                                }}</div>

                                            <div class="content-body pt-1">
                                                <ng-container *ngIf="item.ticket">
                                                    <div class="content-item rounded-none">
                                                        {{ 'ticket.duration' | translate }}:
                                                        {{ resolveTicketDuration(item.ticket) }}
                                                    </div>
                                                    <div *ngIf="!item.activeFromNow"
                                                        class="whitespace-nowrap content-item rounded-none"
                                                        [ngClass]="item.activatedAt.feedbackClass">
                                                        {{ 'ticket.from' | translate }}
                                                        : {{ getFormattedDate(item.activatedAt.value) }}
                                                    </div>
                                                    <div *ngIf="item.activeFromNow"
                                                        class="whitespace-nowrap content-item rounded-none">
                                                        {{ 'ticket.to' | translate }}: {{ 'ticket.now' | translate }}
                                                    </div>

                                                    <div class="flex justify-between pr-2">
                                                        <div class="content-item-short rounded-none">
                                                            {{ 'ticket.zone' | translate }}
                                                            : {{ resolveTicketZone(item.ticket) }}
                                                        </div>
                                                        <div class="rounded-none">
                                                            {{ 'ticket.price' | translate }}
                                                            : {{ item.ticket.priceWithTax / 100 | currency: 'PLN' }}
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="item.product">
                                                    <div class="content-item rounded-none">
                                                        &nbsp;
                                                    </div>
                                                    <div class="content-item rounded-none">
                                                        {{ item.product.description || '&nbsp;' }}
                                                    </div>

                                                    <div class="flex justify-end pr-2">
                                                        <div class="rounded-none">
                                                            {{ 'ticket.price' | translate }}
                                                            : {{ item.product.priceWithTax / 100 | currency: 'PLN' }}
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ng-template #productCard>
                                <app-product-card [product]="item.product">
                                    <i class="md:text-xl text-sm pi pi-times-circle cursor-pointer absolute"
                                        (click)="removeShoppingItemFromCart(item)"></i>
                                </app-product-card>
                            </ng-template>

                            <div class="increment-wrapper p-fluid px-1" [ngClass]="{ 'mt-0.5': item.product }">
                                <p-inputNumber *ngIf="item.ticket?.config?.range == null"
                                    [ngClass]="{ 'ml-0.5': item.ticket }" size="2" min="1" name="item.ticketId + index"
                                    [(ngModel)]="item.count" (onInput)="onCartModifiedCount(item, $event.value)"
                                    [showButtons]="true" buttonLayout="vertical" spinnerMode="vertical"
                                    decrementButtonClass="p-button" incrementButtonClass="p-button"
                                    incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"></p-inputNumber>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="newShoppingListItems.length > 0" class="px-2">

                <ng-container *ngIf="isAnyProductInCart" class="pb-5">
                    <div class="font-bold text-sm pt-5 pb-1">
                        {{ 'cart.deliveryOptions' | translate }}
                    </div>

                    <div class="pb-5">
                        <ng-container *ngIf="suppliers$ | async as suppliers">
                            <div *ngFor="let supplier of suppliers" class="p-field-radiobutton">

                                <p-radioButton name="delivery" [value]="supplier" [(ngModel)]="supplyMethod"
                                    [id]="supplier.name"></p-radioButton>

                                <label [for]="supplier.name">{{ supplier.name }}
                                    - {{ supplier.price / 100 | currency: 'PLN' }}</label>
                            </div>
                        </ng-container>
                    </div>

                    <div #dropdownWrapper>
                        <p-dropdown *ngIf="supplyMethod?.type === 'STATION'" [(ngModel)]="selectedPickupPoint"
                            [options]="stations$ | async" optionLabel="name" optionValue="name"
                            [panelStyle]="{ 'width': dropdownWrapper.offsetWidth + 'px' }"></p-dropdown>
                    </div>
                    <!--                        panelStyle="max-width: 100%"-->
                </ng-container>


                <div class="font-bold text-sm pt-5 pb-1">
                    {{ 'cart.personalDetails' | translate }}
                </div>
                <div class="pb-5">
                    <div class="customer-info-picker px-2 rounded-lg cursor-pointer items-center flex"
                        (click)="displayCustomerInfoLayoutDialog = true"
                        [ngClass]="!customerInfoLayoutFormGroup.valid ? 'border-red-500' : ''">
                        <div class="flex items-center">
                            <i class="pi pi-user text-sm p-2 pr-4"></i>
                            <div>
                                <div class="text-xs customer-info-name-layout rounded-none">
                                    {{ deduceCustomerInfoName() }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="font-bold text-sm pb-1">
                    {{ 'cart.paymentMethod' | translate }}
                </div>
                <div>
                    <div class="payment-method-picker px-2 rounded-lg items-center flex" (click)="choosePaymentMethod()"
                        [ngClass]="paymentMethods?.length < 2 ? 'bg-gray-200 gray-border-200' : 'cursor-pointer'">
                        <div class="flex items-center w-full">
                            <i class="pi pi-money-bill text-sm p-2 pr-4"></i>
                            <div>
                                <div class="text-xs rounded-none">
                                    {{ selectedPaymentMethod?.paymentOperator.name }}
                                </div>
                            </div>

                            <div *ngIf="selectedPaymentMethod.paymentOperator?.name === 'Tpay'"
                                class="w-full flex items-center justify-end pr-3">
                                <img src="../../../../assets/icons/logo-tpay-50x25.svg" style="border: 0"
                                    alt="Logo Tpay" title="Logo Tpay" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-2 pb-4 text-right">
                    {{ 'cart.total' | translate }}: {{ (itemsPrice + supplyMethod?.price) / 100 | currency: 'PLN' }}
                </div>

                <div [formGroup]="invoiceForm" class="mb-3">
                    <div class="flex md:flex-row flex-col justify-start md:justify-between md:items-center pb-2">
                        <label for="invoice" class="form-label">{{ 'cart.customer.invoice' | translate }}: </label>
                        <p-checkbox [formControl]="invoiceForm.controls.invoice" inputid="invoice" [binary]="true"
                            (ngModelChange)="updateVatNumberValidators($event)">
                            <!-- [formControl] is required instead [formControlName] https://github.com/angular/angular/issues/17685 -->
                        </p-checkbox>
                    </div>

                    <div *ngIf="customerInfoLayoutFormGroup.controls.vatNumber.invalid" class="text-sm text-red-500">
                        <div *ngIf="customerInfoLayoutFormGroup.controls.vatNumber.errors.invalidNip">
                            {{ 'validation.invalidNip' | translate }}
                        </div>
                        <div *ngIf="customerInfoLayoutFormGroup.controls.vatNumber.errors.required">
                            {{ 'validation.nipRequired' | translate }}
                        </div>
                    </div>
                </div>

                <div class="flex justify-center text-white h-9">
                    <button pButton class="w-full rounded-lg" type="button" [label]="'cart.buyNow' | translate"
                        [disabled]="customerInfoLayoutFormGroup.controls.vatNumber.invalid || buyButtonClicked"
                        (click)="buyTicketRedirect()">
                    </button>
                </div>
            </div>

            <div *ngIf="newShoppingListItems.length < 1" class="text-center">
                {{ 'cart.empty' | translate }}
            </div>
        </div>
    </ng-template>
</p-dialog>
<!-- ---------------------------- customer info ---------------------------- -->

<p-dialog class="grid customer-info-cart-dialog" [header]="'cart.customer.header' | translate"
    [(visible)]="displayCustomerInfoLayoutDialog" position="center" modal="true" [closable]="true" [draggable]="false"
    (onShow)="changeCustomer(true)" (onHide)="onCloseCustomerInfoDialog()" [contentStyle]="{ overflow: 'visible' }"
    [resizable]="false">
    <p-dropdown #selectCustomerInfo filterBy="label" optionLabel="label" [(ngModel)]="selectedCustomerInfo"
        [options]="mappedCustomerInfos" [placeholder]="'cart.customer.pickData' | translate"
        (onChange)="changeCustomer(false)">
    </p-dropdown>
    <form [formGroup]="customerInfoLayoutFormGroup" class="pt-3 customer-info-form">
        <div class="flex md:flex-row flex-col justify-start md:justify-between md:items-center pb-2">
            <label for="firstName">{{ 'cart.customer.firstName' | translate }}*: </label>
            <input class="flex md:w-3/5 w-full" type="text" formControlName="firstName" pInputText inputid="firstName"
                maxlength="200" />
        </div>
        <div *ngIf="
                customerInfoLayoutFormGroup.controls.firstName.invalid &&
                (customerInfoLayoutFormGroup.controls.firstName.dirty ||
                    customerInfoLayoutFormGroup.controls.firstName.touched)
            " class="text-sm text-red-500">
            <div *ngIf="customerInfoLayoutFormGroup.controls.firstName.errors.required"
                class="flex md:flex-row flex-col">
                <div class="flex w-2/5"></div>
                <div class="flex w-3/5 pb-1">{{ 'validation.required' | translate }}</div>
            </div>
        </div>
        <div class="flex md:flex-row flex-col justify-start md:justify-between md:items-center pb-2">
            <label for="lastName">{{ 'cart.customer.lastName' | translate }}*: </label>
            <input class="flex md:w-3/5 w-full" type="text" formControlName="lastName" pInputText inputid="lastName"
                maxlength="200" />
        </div>
        <div *ngIf="
                customerInfoLayoutFormGroup.controls.lastName.invalid &&
                (customerInfoLayoutFormGroup.controls.lastName.dirty ||
                    customerInfoLayoutFormGroup.controls.lastName.touched)
            " class="text-sm text-red-500">
            <div *ngIf="customerInfoLayoutFormGroup.controls.lastName.errors.required"
                class="flex md:flex-row flex-col">
                <div class="flex w-2/5"></div>
                <div class="flex w-3/5 pb-1">{{ 'validation.required' | translate }}</div>
            </div>
        </div>
        <div class="flex md:flex-row flex-col justify-start md:justify-between md:items-center pb-2">
            <label for="companyName">{{ 'cart.customer.company' | translate }}: </label>
            <input class="flex md:w-3/5 w-full" type="text" formControlName="companyName" pInputText
                inputid="companyName" maxlength="200" />
        </div>
        <div class="flex md:flex-row flex-col justify-start md:justify-between md:items-center pb-2">
            <label for="line1">{{ 'cart.customer.address' | translate }}*: </label>
            <input class="flex md:w-3/5 w-full" type="text" formControlName="line1" pInputText inputid="line1"
                maxlength="200" />
        </div>
        <div *ngIf="
                customerInfoLayoutFormGroup.controls.line1.invalid &&
                (customerInfoLayoutFormGroup.controls.line1.dirty || customerInfoLayoutFormGroup.controls.line1.touched)
            " class="text-sm text-red-500">
            <div *ngIf="customerInfoLayoutFormGroup.controls.line1.errors.required" class="flex md:flex-row flex-col">
                <div class="flex w-2/5"></div>
                <div class="flex w-3/5 pb-1">{{ 'validation.required' | translate }}</div>
            </div>
        </div>
        <div class="flex md:flex-row flex-col justify-start md:justify-between md:items-center pb-2">
            <label for="line2">{{ 'cart.customer.address2' | translate }}</label>
            <input class="flex md:w-3/5 w-full" type="text" formControlName="line2" pInputText inputid="line2"
                maxlength="200" />
        </div>
        <div class="flex md:flex-row flex-col justify-start md:justify-between md:items-center pb-2">
            <label for="postalCode">{{ 'cart.customer.postalCode' | translate }}*: </label>
            <p-inputMask class="flex md:w-3/5 w-full" type="text" formControlName="postalCode" inputid="postalCode"
                mask="99-999" placeholder="99-999"></p-inputMask>
        </div>
        <div *ngIf="
                customerInfoLayoutFormGroup.controls.postalCode.invalid &&
                (customerInfoLayoutFormGroup.controls.postalCode.dirty ||
                    customerInfoLayoutFormGroup.controls.postalCode.touched)
            " class="text-sm text-red-500">
            <div *ngIf="customerInfoLayoutFormGroup.controls.postalCode.errors.required"
                class="flex md:flex-row flex-col">
                <div class="flex w-2/5"></div>
                <div class="flex w-3/5 pb-1">{{ 'validation.required' | translate }}</div>
            </div>
        </div>
        <div class="flex md:flex-row flex-col justify-start md:justify-between md:items-center pb-2">
            <label for="city">{{ 'cart.customer.city' | translate }}*: </label>
            <input class="flex md:w-3/5 w-full" type="text" formControlName="city" pInputText inputid="city"
                maxlength="200" />
        </div>
        <div *ngIf="
                customerInfoLayoutFormGroup.controls.city.invalid &&
                (customerInfoLayoutFormGroup.controls.city.dirty || customerInfoLayoutFormGroup.controls.city.touched)
            " class="text-sm text-red-500">
            <div *ngIf="customerInfoLayoutFormGroup.controls.city.errors.required" class="flex md:flex-row flex-col">
                <div class="flex w-2/5"></div>
                <div class="flex w-3/5 pb-1">{{ 'validation.required' | translate }}</div>
            </div>
        </div>
        <div class="flex md:flex-row flex-col justify-start md:justify-between md:items-center pb-2">
            <label for="phoneNumber" class="form-label">{{ 'cart.customer.phone' | translate }}: </label>
            <p-inputMask class="flex md:w-3/5 w-full" type="text" formControlName="phoneNumber" inputid="phoneNumber"
                mask="(+99) 999 999 999" placeholder="(+48) 999 999 999"></p-inputMask>
        </div>
        <div class="flex md:flex-row flex-col justify-start md:justify-between md:items-center pb-2">
            <label for="vatNumber" class="form-label">
                {{ 'cart.customer.nip' | translate }}:
                <span *ngIf="invoiceForm.controls.invoice.value">*</span>
            </label>
            <p-inputMask class="flex md:w-3/5 w-full" type="text" formControlName="vatNumber" inputid="vatNumber"
                mask="9999999999" placeholder="9999999999"></p-inputMask>
        </div>
        <ng-container *ngIf="selectedCustomerInfo?.label === 'Nowe dane'">
            <div class="flex md:flex-row flex-col justify-start md:justify-between md:items-center pb-2">
                <p-checkbox [(ngModel)]="checkBoxValue" [binary]="true" (onChange)="getCustomerInfoLabel()"
                    [ngModelOptions]="{ standalone: true }"></p-checkbox>
                <span class="text-sm">
                    {{ 'cart.customer.saveData' | translate }}
                </span>
            </div>
        </ng-container>
        <ng-container *ngIf="checkBoxValue">
            <div class="flex md:flex-row flex-col justify-start md:justify-between md:items-center pb-2">
                <label>* {{ 'cart.customer.name' | translate }}: </label>
                <input class="flex md:w-3/5 w-full" type="text" pInputText inputid="city" maxlength="200"
                    [(ngModel)]="nameTextValue" [ngModelOptions]="{ standalone: true }" />
            </div>
            <div *ngIf="checkBoxValue && !nameTextValue" class="flex md:flex-row flex-col text-sm text-red-500">
                <div class="flex w-2/5"></div>
                <div class="flex w-3/5 pb-1">{{ 'validation.required' | translate }}</div>
            </div>
        </ng-container>

        <div class="w-full flex justify-center items-center pt-7">
            <button class="md:w-96 w-full" pButton type="submit" [label]="'cart.customer.accept' | translate"
                [disabled]="!customerInfoLayoutFormGroup.valid || (checkBoxValue && !nameTextValue)"
                (click)="clickChooseCustomerInfo()"></button>
        </div>
    </form>
</p-dialog>

<!-- --------------------------- payment method ---------------------------- -->

<p-dialog class="grid" [header]="'cart.choosePaymentMethod' | translate" [(visible)]="displayPaymentMethodDialog"
    position="center" [closable]="true" [draggable]="false" [resizable]="false" [modal]="true">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 grid-flow-col">
        <div *ngFor="let paymentMethod of selectedTransportAuthority?.paymentMethods">
            <div class="justify-center flex">
                <div class="payment-method-wrapper rounded-lg cursor-pointer items-center justify-center flex"
                    [ngClass]="paymentMethod === selectedPaymentMethod ? 'highlight-background' : ''"
                    (click)="selectPaymentMethod(paymentMethod)">
                    <div class="font-bold text-l">{{ paymentMethod?.paymentOperator.name }}</div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>

<!-- ----------------------------- confirm buy ----------------------------- -->

<p-dialog class="grid" header="" [(visible)]="displayPaymentRedirectDialog" position="center" [closable]="false"
    [draggable]="false" [resizable]="false" [modal]="true">
    <div class="grid grid-cols-1 gap-4 grid-flow-row justify-center">
        <div class="flex justify-self-center">
            {{ 'cart.paymentInProgress' | translate }}
        </div>
        <div class="flex justify-self-center"><i class="text-xl pi pi-spin pi-spinner"></i></div>
    </div>
</p-dialog>

<ng-content></ng-content>

<div class="footer-pusher"></div>
<footer class="h-10">
    <app-footer></app-footer>
</footer>

<!-- ---------------------------- confirmation ----------------------------- -->
<p-confirmDialog #confirmationDialog [header]="'cart.confirm' | translate" icon="pi pi-exclamation-triangle"
    (onHide)="onHideConfirmationDialog($event)">
    <p-footer>
        <button type="button" pButton icon="pi pi-check" [label]="'cart.yes' | translate"
            (click)="confirmationDialog.accept()"></button>
        <button type="button" pButton icon="pi pi-times" [label]="'cart.no' | translate"
            (click)="confirmationDialog.reject()"></button>
    </p-footer>
</p-confirmDialog>