import * as AppStateActions from '../../features/modules/state/app-state.actions';

import { Select, Store } from '@ngxs/store';
import { debounceTime, filter } from 'rxjs/operators';

import { Api } from '../../api/src/Api';
import { AppState } from '../modules/state/app.state';
import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { RefreshTokenService } from '../../shared/services/refresh-token.service';
import { Router } from '@angular/router';

@Component({
    selector: `app-main`,
    styleUrls: [`./main.component.scss`],
    templateUrl: `main.component.html`,
})
export class MainComponent {
    @Select(AppState.userData) userData$!: Observable<any>;

    @Select(AppState.serviceMode)
    readonly serviceMode$!: Observable<boolean>;

    popupVisible = false;
    popupText = ``;
    user: any = undefined;
    denyButtonDisabled = true;
    popupPolicies: any[] = [];

    constructor(
        private readonly refreshTokenService: RefreshTokenService,
        private readonly apiService: Api,
        private readonly store: Store,
        private readonly messageService: MessageService,
        private readonly router: Router,
    ) {}

    async ngOnInit() {
        await this.refreshTokenService.refreshUser().catch(() => {});

        this.userData$
            .pipe(
                filter((user) => {
                    return typeof user !== `undefined`;
                }),
                debounceTime(500),
            )
            .subscribe(async (user) => {
                this.user = user;
                await this.getPopupPolicies();

                if (user) {
                    this.denyButtonDisabled = false;
                    const userHasUnapprovedPopupPolicy = await this.userHasUnapprovedPopupPolicy();
                    if (userHasUnapprovedPopupPolicy) {
                        await this.showPopup(`logged`);
                    }
                    return;
                }

                this.denyButtonDisabled = true;
                await this.showPopup(`unlogged`);
            });
    }

    async userHasUnapprovedPopupPolicy() {
        const firstUnapprovedPopupPolicy = this.popupPolicies.find((popupPolicy) => {
            const found = this.user.approvedPolicies.find((approvedPolicy: any) => {
                return approvedPolicy.policyId === popupPolicy.id;
            });

            return !found;
        });

        return Boolean(firstUnapprovedPopupPolicy);
    }

    async getPopupPolicies() {
        const res = (await this.apiService.users.usersControllerGetAllPolicies()) as any;
        this.popupPolicies = res.data.filter((policy: any) => {
            return policy.active && policy.type === `popup`;
        });
    }

    async showPopup(key: string) {
        const popupShown = localStorage.getItem(`popup-${key}-shown`);
        if (popupShown === `true`) {
            return;
        }

        if (this.popupPolicies.length > 0) {
            this.popupVisible = true;
        }

        this.popupText = this.popupPolicies
            .map((policy: any) => {
                return `${policy.description}<br/><br/>`;
            })
            .join(``);
    }

    async acceptPopup() {
        this.popupVisible = false;

        if (!this.user) {
            localStorage.setItem(`popup-unlogged-shown`, `true`);
            return;
        }

        // Approve policy.
        await this.apiService.users.usersControllerConnectApprovedPolicies(
            this.user.id,
            this.popupPolicies.map((popupPolicy) => {
                return popupPolicy.id;
            }),
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(`token`)}`,
                },
            },
        );

        this.store.dispatch(new AppStateActions.GetUser());
    }

    async denyPopup() {
        this.popupVisible = false;

        if (!this.user) {
            localStorage.setItem(`popup-unlogged-shown`, `true`);
            return;
        }

        // Log out.
        const token = localStorage.getItem(`token`);

        await this.refreshTokenService.clearUserTimeout();

        await this.apiService.users.usersControllerLogout({
            headers: {
                Authorization: `Bearer ${token}`,
            },
            credentials: `include`,
        });

        localStorage.removeItem(`token`);

        this.store.dispatch(new AppStateActions.GetUser());

        this.messageService.add({
            severity: `success`,
            summary: `Sukces`,
            detail: `Wylogowanie nastąpiło pomyślnie`,
        });

        void this.router.navigate([`./uzytkownik/logowanie`]);
    }
}
